import axios from "axios";
import dayjs from "dayjs";
import { CONSTANTS, getErrorMessage } from "../global/index";
const {
  ERROR_MSG,
  ERROR_STATUS,
} = CONSTANTS;
const ERROR_OBJ = {
  success: false,
  status: ERROR_STATUS,
  code: 500,
  message: ERROR_MSG,
  data: null
};


export default {
  setToken(token) {
    localStorage.setItem("tkn", token);
  },

  getToken() {
    const token = localStorage.getItem("tkn");
    if(!token) return null
    return token
  },

  isAdmin(user) {
    if (user?.role !== "admin" && user.role !== "legrafica") return { admin: false, type: user.role }
    return { admin: true, type: user.role };
  },

  async tokenexp() {
    const response = await this.getData();
    if (!response.success) return true;

    const identity = response.data;
    const identity_exp = new Date(0).setUTCSeconds(identity.exp);
    const date = dayjs().valueOf();
    const exp = date > identity_exp;
    return exp;
  },

  async getData() {
    try {
      const token = this.getToken();
      if (token === null) return getErrorMessage('Token no valido, vuelve a iniciar sesión.')
      const headers = { Authorization: token };
      const response = await axios.post(`/validate-token`, null, { headers });
      return response.data;
    } catch (error) {
      if(!error?.response?.data?.message) return  ERROR_OBJ;
      return getErrorMessage(error.response.data.message)
    }
  },

  async createAuth() {
    try {
      const token = this.getToken();
      if (token === null) return getErrorMessage('Token no valido, vuelve a iniciar sesión.')

      const headers = { Authorization: token };
      const response = await axios.post(`/validate-token`, null, { headers });
      return response.data
    } catch (error) {
      if(!error?.response?.data?.message) return  ERROR_OBJ;
        return getErrorMessage(error.response.data.message)
    }
  },

  setAth(ath) {
    localStorage.setItem("ath", ath);
  },

  async getAth() {
    const ath = localStorage.getItem("ath");
    if(!ath) return false
    try {
      const token = this.getToken();
      if (token === null) return getErrorMessage('Token no valido, vuelve a iniciar sesión.')

      const headers = { Authorization: token };
      const response = await axios.post(
        `/validate-token`,
        null,
        { headers }
      );
      
      return response.data.success
    } catch (error) {
      return false
    }
  },
};
